import React from "react"
import {Link} from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {graphql} from 'gatsby'
import {Container, Col, Row} from "react-bootstrap"
import PriceMenu from "../components/priceMenu"
import {FaCheckCircle, FaCheck} from 'react-icons/fa';
import OwlCarousel from "../components/owlCarousel";
import Phones from "../components/phones";

function FirstScreen() {
  return (<OwlCarousel />)
}

const IndexPage = (props) => (
  <Layout
    location={props.location}
    firstScreen={<FirstScreen />}
  >
    <SEO
      title={props.data.page.frontmatter.title}
      description={props.data.page.frontmatter.description}
    />

    <Container>
      <Row>
        <Col>
          <h1 className="my-5">{props.data.page.frontmatter.title}</h1>
          <div dangerouslySetInnerHTML={{__html: props.data.page.html}} />
        </Col>
      </Row>
    </Container>

    <Container>
      <Row className="justify-content-md-center">
        <Col lg="12">
          <h2 className="my-4">Пункты приема металлолома</h2>
          <div style={{border: "1px solid #ddd"}} className="p-3">
            <PriceMenu location={props.location} lg="3" contacts={props.data.site.siteMetadata.contacts} />
          </div>
        </Col>
      </Row>
    </Container>

    <Container>
      <Row>
        <Col lg="6">
          <h2 className="my-4 mt-5">Услуги «Алмет»</h2>
          <ul style={{fontSize: "1.2rem", listStyle: "none", marginLeft: "0px"}} className="m-0 pl-2">
            <li className="py-1"><FaCheck style={{marginRight: "0.5rem", fontSize: "0.8rem"}} />Демонтаж объектов.</li>
            <li className="py-1"><FaCheck style={{marginRight: "0.5rem", fontSize: "0.8rem"}} />Анализ химического состава сплавов на рентгено-флуоресцентном анализаторе Thermo NITON XL3t.</li>
            <li className="py-1"><FaCheck style={{marginRight: "0.5rem", fontSize: "0.8rem"}} />БЕСПЛАТНЫЙ самовывоз собственным автотранспортом с Вашего склада при условии тоннажа не менее 1 тонны. <Link to="/besplatnyj-samovyvoz/">Узнать подробнее.</Link></li>
          </ul>
          <h2 className="my-4 mt-5 p-2">Преимущества «Алмет»</h2>
          {props.data.page.frontmatter.features.map((item, index) => (
            <p className="pl-2" key={index}>
              <FaCheckCircle style={{marginRight: "0.5rem", fontSize: "0.8rem"}} />
              {item.text}
            </p>
          ))}
        </Col>
        <Col lg="6" className="p-5 d-none d-lg-block">
          <Img
            fluid={props.data.services.childImageSharp.fluid}
            className="sticky my-auto d-block"
            alt={`Сдать лом цветного и черного металла в Барнауле, Рубцовске, Новоалтайске.`}
            title={`Сдать лом цветного и черного металла в Барнауле, Рубцовске, Новоалтайске.`}
          />
        </Col>
      </Row>

    </Container>

    <Container>
      <Row className="justify-content-md-center my-5" style={{
        border: "1px solid #ddd"
      }}>
        <Col className="p-3 p-lg-5">
          <h2 className="my-3">
            Постоянным поставщикам
          </h2>
          <p>
            Уважаемые поставщики!
          </p><p>
            Наша компания с 2006 года ведет свою деятельность по заготовке лома цветных и черных металлов. Мы хотим поблагодарить Вас за то, что Вы выбираете нашу компанию в качестве своего партнера.
            Мы постоянно работаем над тем, чтобы сотрудничество с нами было для Вас максимально быстрым, удобным и, главное, выгодным! Максимальные цены, быстрая, точная приемка, моментальный расчет, доброжелательное отношение, работа по субботам.
          </p>
          <h4>Главное для нас - наши поставщики!</h4>
          <p>
            Для того чтобы поблагодарить наших постоянных клиентов, сделав работу с нами еще более выгодной, мы предлагаем индивидуальные цены.
          </p><p>
            Для уточнения информации позвоните по телефону менеджеру или напишите WhatsApp:
            <Phones phones={[
               {number: '8(964)087-78-23', type: 'phone', reachGoal: "9559"},
               {number: '8(964)087-78-23', type: 'whatsapp', reachGoal: "9560"},
            ]} className="btn p-0 f-8 ml-3" />
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        prices {
          sheet
          title
        }
        contacts {
          address
          city
        }
      }
    }
    page: markdownRemark(frontmatter: {slug: {eq: "home"}}) {
      html
      frontmatter {
        title
        slug
        description
        features {
          text
        }
      }
    }
    services: file(relativePath: { eq: "lomovoz.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, jpegProgressive: true, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    features: file(relativePath: { eq: "women.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 400, jpegProgressive: true, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    backgrounds: allFile (filter: {sourceInstanceName: {eq: "backgrounds"}}){
      nodes {
        relativePath
        childImageSharp {
          fluid (maxWidth: 4000, quality: 100){
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
